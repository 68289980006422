html {
  /* background-color: rgb(27, 24, 45); /*bgcolor*/
  /* color: white; */
}
body {
  margin: 0;
  padding: 0;
}
main {
  padding: 10px;
}
div {
  
}
.row{
  display: flex;
}

.col{
  display: flex;
  flex-direction: column;
}

a{
  text-decoration: none;
  /* color: white; */
}
a:focus{
  text-decoration: none;
  /* color: white; */
}
a:visited{
  text-decoration: none;
  color: black;
}
a:link{
  text-decoration: none;
  color: black;
}
a:active{
  text-decoration: none;
  color: black;
}

[data-amplify-authenticator]{
  width: 100%;
  margin-top: 10%;
}
[data-amplify-router-content] > div {
  flex-direction: column;
  width: 100%;
}
[data-amplify-authenticator] [data-amplify-footer]{
  justify-content: center;
}
[data-amplify-authenticator] [data-amplify-router] {
  /* background-color: rgb(35, 32, 51) !important; */
}
.amplify-input{
  /* --amplify-components-fieldcontrol-color: white; */
}
.amplify-button--link{
  /* --amplify-components-button-link-color: white; */
}
.amplify-button--link:hover{
  /* --amplify-components-button-link-hover-background-color: none !important;
  --amplify-components-button-link-hover-color: white !important;
  --amplify-components-button-link-hover-border: none !important; */
}
.amplify-button--link:active{
  /* --amplify-components-button-link-active-background-color: none !important;
  --amplify-components-button-link-active-color: white !important;
  --amplify-components-button-link-active-border: none !important; */
}
.amplify-button--link:focus{
  /* --amplify-components-button-link-focus-background-color: none !important;
  --amplify-components-button-link-focus-color: white !important;
  --amplify-components-button-link-focus-border: none !important; */
}
.amplify-button--primary{
  /* --amplify-components-button-primary-background-color: rgb(27, 24, 45); */
}
.amplify-button--primary:hover{
  /* --amplify-components-button-primary-hover-background-color: rgb(31, 28, 48) !important; */
}
.amplify-button--primary:active{
  /* --amplify-components-button-primary-active-background-color: rgb(31, 28, 48) !important; */
}
.amplify-button--primary:focus{
  /* --amplify-components-button-primary-focus-background-color: rgb(31, 28, 48) !important; */
}
.amplify-button{
  /* --amplify-components-button-color: white; */
}
.amplify-field-group__field-wrapper{
  /* background-color: rgb(27, 24, 45) !important; */
}
@media screen and (min-width: 992px) {
  [data-amplify-authenticator]{
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%); */
  }
}
[data-amplify-authenticator]{
  width: 100%;
}
[data-amplify-authenticator] [data-amplify-container]{
  width: 100%;
}
[data-amplify-authenticator] [data-amplify-router]{
  width: 100%;
}
[data-amplify-router-content]{
  width: 100%;
}

.react-datepicker {
  /* background-color: rgb(31, 28, 48) !important;
  color: white !important; */
}

.react-datepicker__header {
  /* background-color: rgb(31, 28, 48) !important;
  color: white !important; */
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  /* background-color: rgb(31, 28, 48) !important;
  color: white !important; */
}

.react-datepicker__time-list,
.react-datepicker-time__header {
  /* background-color: rgb(31, 28, 48) !important;
  color: white !important; */
}

.react-datepicker__time-list-item,
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
  /* background-color: rgb(31, 28, 48) !important;
  color: white !important; */
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
  /* background-color: rgb(245, 244, 250) !important;
  color: rgb(14, 9, 9) !important; */
}

.react-datepicker__input-container input,
.react-datepicker-ignore-onclickoutside{
  /* background-color: rgb(35, 32, 51) !important;
  color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(155, 151, 172); */
  border: 1px solid grey;
  border-radius: 8px;
  height: 2em;
  width: 8em;
}

.react-datepicker__day:hover,
.react-datepicker__time-list-item:hover{
  /* background-color: #4b81ab !important;
  color: white !important; */
}

.react-datepicker-wrapper{
  width: auto !important;
}

.grid-cell:hover{
  /* background-color: white; */
}

.grid-cell[data-selected="true"] {
  /* background-color: white; */
}
.dot{
  border-radius: 100px;
  width: .5em;
  height: .5em;
}
.v-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
table, th, td {
  border: 1px solid lightgray;
  border-collapse: collapse;
}
table{
  width: 100%;
}